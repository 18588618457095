exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-approach-tsx": () => import("./../../../src/pages/about/approach.tsx" /* webpackChunkName: "component---src-pages-about-approach-tsx" */),
  "component---src-pages-about-clinic-tsx": () => import("./../../../src/pages/about/clinic.tsx" /* webpackChunkName: "component---src-pages-about-clinic-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-about-testimonials-tsx": () => import("./../../../src/pages/about/testimonials.tsx" /* webpackChunkName: "component---src-pages-about-testimonials-tsx" */),
  "component---src-pages-blog-categories-tsx": () => import("./../../../src/pages/blog/categories.tsx" /* webpackChunkName: "component---src-pages-blog-categories-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-direct-access-tsx": () => import("./../../../src/pages/resources/direct-access.tsx" /* webpackChunkName: "component---src-pages-resources-direct-access-tsx" */),
  "component---src-pages-resources-faq-tsx": () => import("./../../../src/pages/resources/faq.tsx" /* webpackChunkName: "component---src-pages-resources-faq-tsx" */),
  "component---src-pages-resources-insurance-tsx": () => import("./../../../src/pages/resources/insurance.tsx" /* webpackChunkName: "component---src-pages-resources-insurance-tsx" */),
  "component---src-pages-resources-patient-forms-tsx": () => import("./../../../src/pages/resources/patient-forms.tsx" /* webpackChunkName: "component---src-pages-resources-patient-forms-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-blog-category-page-tsx": () => import("./../../../src/templates/blog-category-page.tsx" /* webpackChunkName: "component---src-templates-blog-category-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/service-page.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */)
}

